<template>
  <div class="d-flex align-items-center w-100">
    <div class="flex-fill _line"></div>
    <slot></slot>
    <div class="flex-fill _line"></div>
  </div>
</template>

<script>
export default {
  name: 'TextDivider',
};
</script>

<style lang="scss" scoped>
._line {
  height: 1px;
  background-color: var(--divider);
}
</style>
