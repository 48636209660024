<template>
  <div class="_header d-flex align-items-center justify-content-between pt-2 pb-2 pl-3 pr-3">
    <img
      v-if="$store.state.loginLogoSrc"
      class="logo logo-footer"
      :src="$store.state.loginLogoSrc"
      alt="Ecosystem Logo"
      @click="handleLogoClick"
    />
  </div>
</template>

<script>
export default {
  name: 'Header',

  methods: {
    handleLogoClick() {
      window.open('https://www.rise-x.io/');
    },
  },
};
</script>

<style lang="scss" scoped>
._header {
  border-bottom: 1px solid var(--background-card);
}
.logo-header {
  cursor: pointer;
}
</style>
