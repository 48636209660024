import Vue from 'vue';
import Vuex from 'vuex';
import resource from '@/resource.config';

// store modules
import userProfile from '@/store/userProfile';
import navigation from '@/store/navigation';
import appDrawer from '@/store/appDrawer';
import verifyEmail from '@/store/verifyEmail';
import ovNavigation from '@/store/ovNavigation';

Vue.use(Vuex);
export default () => {
  return new Vuex.Store({
    state: {
      // used to persist user email when click forgot password
      username: '',

      // base url of api
      apiBaseUrl: resource['api-base-url'],

      // open/close drawer
      active: false,

      // position of drawer
      position: 'right',

      // selector of site's nav bar
      navBarSelector: '',

      // url redirect after logged in
      loginRedirectUrl: '',

      // url for the help page in notion
      helpUrl: resource['help-url'],

      // url for the rise-x page
      //temporary not in use as API doesn't support environment website hyperlink
      homeUrl: resource['rise-x-url'],

      // logo to display on login
      loginLogoSrc: resource['login-logo'],

      environmentName: resource['environment-name'],

      // where to mount app tray button
      appTrayMountSelector: '',

      // navigate not logged in to specific view
      navigateUnauthorizedTo: '',

      // primary color of environment
      buttonColor: resource['button-color'],
    },
    modules: {
      userProfile,
      navigation,
      appDrawer,
      verifyEmail,
      ovNavigation,
    },
  });
};
