var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_rx_" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _c(
          "div",
          {
            ref: "DrawerButton",
            staticClass: "d-flex h-100 align-items-center position-relative",
          },
          [
            _vm.userData
              ? _c("DrawerButton", {
                  class: _vm.$store.state.appDrawer.show
                    ? "_active mr-3"
                    : "mr-3",
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$store.state.appDrawer.show =
                        !_vm.$store.state.appDrawer.show
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("AppTrayCard", {
              staticClass: "position-absolute mt-2",
              staticStyle: { left: "0", top: "100%" },
              model: {
                value: _vm.$store.state.appDrawer.show,
                callback: function ($$v) {
                  _vm.$set(_vm.$store.state.appDrawer, "show", $$v)
                },
                expression: "$store.state.appDrawer.show",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.userData
          ? _c("ToggleButton", {
              class: _vm.$store.state.active ? "_active" : "",
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.$store.state.active = !_vm.$store.state.active
                },
              },
            })
          : _c("div", { class: "_login-btn-container rise-x" }, [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$store.commit("ovNavigation/navigate", "login")
                    },
                  },
                },
                [_vm._v("Login")]
              ),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { ref: "Content", staticClass: "_rx_" },
      [
        _vm.ovComponent
          ? _c(
              "OverlayView",
              [
                _c(_vm.ovComponent, {
                  tag: "component",
                  on: { "last-element-blur": _vm.onLastAccessibleElementBlur },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "MainWrapper",
            class: _vm.wrapperClass,
            style: _vm.getWrapperStyle(),
          },
          [
            _c(
              "div",
              { staticClass: "_extension-content" },
              [
                _vm.activeComponent.extension && _vm.extensionPayload
                  ? _c(_vm.activeComponent.extension, {
                      tag: "component",
                      attrs: { payload: _vm.extensionPayload },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_main-content" },
              [
                _vm.userStatus.isLoading
                  ? [
                      _c(
                        "div",
                        { staticClass: "p-4 text-center" },
                        [_c("Spinner")],
                        1
                      ),
                    ]
                  : _vm.userStatus.errorMessage
                  ? [
                      _c("div", { staticClass: "p-4 text-center" }, [
                        _c("h4", { staticClass: "m-0" }, [
                          _vm._v("Unable to check session."),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "m-0" }, [
                          _vm._v(_vm._s(_vm.userStatus.errorMessage)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn-sm mt-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.loadUser.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Try Again")]
                        ),
                      ]),
                    ]
                  : [
                      _vm.activeComponent.component
                        ? _c(_vm.activeComponent.component, {
                            tag: "component",
                          })
                        : _vm._e(),
                    ],
              ],
              2
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }