<template>
  <div class="position-relative">
    <div class="p-4 form-container">
      <!-- logo -->
      <div v-if="$store.state.loginLogoSrc" class="text-center pb-4">
        <img class="logo logo-header" :src="$store.state.loginLogoSrc" alt="Login Logo" />
      </div>

      <div v-if="status.isSuccess && !isSendLinkView" class="text-center py-4">
        <p class="mb-0">Please check your email to continue</p>
        <p class="diana-theme-text-secondary mb-0">
          A reset password email was successfully sent to:
        </p>
        <p class="diana-theme-text-secondary">{{ $store.state.username }}</p>
        <a @click="isSendLinkView = true" :style='{color: $store.state.buttonColor, cursor: "pointer", textDecoration: "none"}'>I didn’t receive a link</a>
      </div>

      <div v-else>
        <p class="text-center mb-0">Forgot Password?</p>
        <p class="diana-theme-text-secondary text-center">We'll send a recovery link to</p>

        <form @submit.prevent="submit">
          <fieldset :disabled="status.isLoading">
            <!-- email input -->
            <input
              ref='firstElement'
              tabindex="1"
              v-model="$store.state.username"
              class="input-lg mb-3 accessibility-element"
              placeholder="Email"
              disabled
            />

            <!-- error message -->
            <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="mb-3" />

            <!-- submit button -->
            <button
              tabindex="2"
              type="submit"
              class="btn-lg btn-primary w-100 mb-3 accessibility-element"
              v-bind:style="{ backgroundColor: $store.state.buttonColor }"
            >
              <Spinner v-if="status.isLoading" />
              <span v-else>Send a link</span>
            </button>
          </fieldset>
        </form>
      </div>

      <div class="text-center">
        <div class="d-inline-flex">
          <a
            tabindex="3"
            @click.prevent="openHelpScreen"
            class="diana-theme-text-primary accessibility-link"
            style="cursor: pointer"
          >Need help?</a
          >
          <span class="mx-2" style="align-self: center">&bull;</span>
          <a
            tabindex="4"
            href="#"
            class="diana-theme-text-primary accessibility-link"
            @click.prevent="$store.commit('ovNavigation/navigate', 'register')"
          >Create an account</a
          >
        </div>
      </div>

      <button
        tabindex="5"
        class="btn back-arrow accessibility-element"
        type="button"
        @click="$store.commit('ovNavigation/navigate', 'login')"
        @blur="$emit('last-element-blur', $refs.firstElement)"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5825 6.5925H3.4125L8.2925 1.7125C8.6825 1.3225 8.6825 0.6825 8.2925 0.2925C7.9025 -0.0975 7.2725 -0.0975 6.8825 0.2925L0.2925 6.8825C-0.0975 7.2725 -0.0975 7.9025 0.2925 8.2925L6.8825 14.8825C7.2725 15.2725 7.9025 15.2725 8.2925 14.8825C8.6825 14.4925 8.6825 13.8625 8.2925 13.4725L3.4125 8.5925H14.5825C15.1325 8.5925 15.5825 8.1425 15.5825 7.5925C15.5825 7.0425 15.1325 6.5925 14.5825 6.5925Z" fill="var(--text-primary)" fill-opacity="0.54"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import querystring from 'querystring';
import ErrorMessage from '@/components/ErorrMessage.vue';

export default {
  name: 'ForgotPassword',
  components: { ErrorMessage },
  data: () => ({
    status: {
      isLoading: false,
      isSuccess: false,
      errorMessage: '',
    },
    isSendLinkView: true
  }),
  methods: {
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.isSuccess = false;
        this.status.errorMessage = '';

        const queryData = {
          returnUrl: window.location.href,
          userEmail: this.$store.state.username,
        };

        const payload = {
          url: `account/resetPassword?${querystring.encode(queryData)}`,
          method: 'POST',
          data: '',
        };

        await this.$request(payload);
        this.status.isSuccess = true;
        this.isSendLinkView = false;
      } catch (e) {
        console.error(e.message);
        this.status.errorMessage = 'An unknown error has occurred. Please try again.';
      } finally {
        this.status.isLoading = false;
      }
    },
    openHelpScreen() {
      // Small delay so users know what is happening
      setTimeout(() => {
        window.open(this.$store.state.helpUrl, '_blank', 'noopener noreferrer');
        this.clearMessages();
      }, 500);
    },
  },
};
</script>

<style scoped lang='scss'>
.form-container {
  display: flex;
  flex-direction: column;
  .back-arrow {
    order: -1;
    align-self: self-start;
  }
}
</style>
