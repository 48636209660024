var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    [
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.checked)
          },
        },
      }),
      _vm._v(" "),
      _c("span"),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }