import Login from '@/overlay-views/Login';
import Register from '@/overlay-views/Register';
import VerifyEmail from '@/overlay-views/VerifyEmail';
import ForgotPassword from '@/overlay-views/ForgotPassword';
import ResetPassword from '@/overlay-views/ResetPassword';

// Overlay View Navigation
const ovNavigation = {
  state: {
    // current overlay view
    currentView: null,

    // views map
    views: {
      login: {
        component: Login,
      },
      register: {
        component: Register,
      },
      verifyEmail: {
        component: VerifyEmail,
      },
      forgotPassword: {
        component: ForgotPassword,
      },
      resetPassword: {
        component: ResetPassword,
      },
    },
  },
  getters: {
    currentViewComponent(state) {
      if (state.currentView) {
        const view = state.views[state.currentView];
        return view ? view.component : null;
      }
      return null;
    },
  },
  mutations: {
    // navigate view
    navigate(state, view) {
      state.currentView = view;
    },
  },
  namespaced: true,
};

export default ovNavigation;
