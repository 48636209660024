<template>
  <div class="_user-avatar">{{ initials }}</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UserAvatar',
  computed: {
    ...mapState('userProfile', {
      userData: 'data',
    }),
    initials() {
      if (
        this.userData &&
        this.userData.user &&
        typeof this.userData.user.displayName === 'string'
      ) {
        const sanitized = this.userData.user.displayName.replace(/[^\sa-zA-Z]/gi, '');
        const initials = sanitized
          .split(' ')
          .map((a) => a.trim())
          .filter((a) => a)
          .map((a) => a[0])
          .slice(0, 2)
          .join('');
        if (initials) return initials.toUpperCase();
      }
      return '??';
    },
  },
};
</script>

<style lang="scss" scoped>
$_size: 40px;

._user-avatar {
  background-color: #26959845;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $_size;
  height: $_size;
  border-radius: 100em;
  user-select: none;
  color: $primary;
  font-weight: normal;
  position: relative;
  z-index: 1;
  font-size: 16px;
}

img {
  display: block;
  width: $_size;
  height: $_size;
  position: relative;
  z-index: 1;
}
</style>
