<template>
  <div class="container py-4">
    <component :is="formComponent" v-if="formComponent" />
  </div>
</template>

<script>
import ProfileForm from './ProfileForm';
import SecurityForm from './SecurityForm';
import SettingsForm from './SettingsForm';
import CompanyForm from './CompanyForm';

export default {
  name: 'UserProfileExtension',
  props: ['payload'],
  computed: {
    formComponent() {
      const map = {
        profile: ProfileForm,
        security: SecurityForm,
        settings: SettingsForm,
        company: CompanyForm,
      };
      return map[(this.payload || {}).id] || null;
    },
  },
};
</script>

<style scoped></style>
