<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="status.isLoading">
      <div class="d-flex align-items-center">
        <!-- title -->
        <div class="flex-fill">
          <h1 class="m-0">Profile</h1>
        </div>

        <!-- save button -->
        <div>
          <button type="submit" class="btn btn-primary">
            <Spinner v-if="status.isLoading" />
            <span v-else>Save Changes</span>
          </button>
        </div>
      </div>

      <div class="mt-5">
        <!-- error message -->
        <AlertMessage v-model="status.errorMessage" class="mb-5 bg-danger" />

        <!-- success message -->
        <AlertMessage v-model="status.successMessage" class="mb-5 bg-success" />

        <div class="row">
          <!-- full name -->
          <div class="col-6">
            <label class="small">Full name</label>
            <input v-model="formData.displayName" type="text" required="required" />
          </div>

          <!-- mobile number -->
          <div class="col-6">
            <label class="small">Mobile no.</label>
            <input v-model="formData.phoneNumber" type="text" required="required" />
          </div>

          <!-- primary email -->
          <div class="col-6">
            <label class="small">Primary email</label>
            <input v-model="formData.email" type="email" required="required" />
          </div>

          <!-- secondary email -->
          <div class="col-6">
            <label class="small">Secondary email</label>
            <input v-model="formData.secondaryEmail" type="email" />
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProfileForm',
  data: () => ({
    formData: {
      displayName: '',
      phoneNumber: '',
      email: '',
      secondaryEmail: '',
    },
    status: {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    },
  }),
  computed: {
    ...mapState('userProfile', {
      userData: 'data',
    }),
  },
  created() {
    if (this.userData)
      this.formData = {
        ...this.formData,
        ...this.userData.user,
      };
  },
  methods: {
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        this.status.successMessage = '';

        await this.$request({
          url: `/api/v1.0/User/${this.formData.id}/update`,
          method: 'POST',
          data: JSON.stringify(this.formData),
          contentType: 'application/json',
        });

        Object.assign(this.$store.state.userProfile.data.user, this.formData);
        this.status.successMessage = 'Changes saved successfully.';
      } catch (e) {
        this.status.errorMessage = e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
