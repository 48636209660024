var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _c("div", { staticClass: "p-4 form-container" }, [
      _vm.$store.state.loginLogoSrc
        ? _c("div", { staticClass: "text-center pb-4" }, [
            _c("img", {
              staticClass: "logo logo-header",
              attrs: { src: _vm.$store.state.loginLogoSrc, alt: "Login Logo" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.status.isSuccess && !_vm.isSendLinkView
        ? _c("div", { staticClass: "text-center py-4" }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Please check your email to continue"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "diana-theme-text-secondary mb-0" }, [
              _vm._v(
                "\n        A reset password email was successfully sent to:\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "diana-theme-text-secondary" }, [
              _vm._v(_vm._s(_vm.$store.state.username)),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                style: {
                  color: _vm.$store.state.buttonColor,
                  cursor: "pointer",
                  textDecoration: "none",
                },
                on: {
                  click: function ($event) {
                    _vm.isSendLinkView = true
                  },
                },
              },
              [_vm._v("I didn’t receive a link")]
            ),
          ])
        : _c("div", [
            _c("p", { staticClass: "text-center mb-0" }, [
              _vm._v("Forgot Password?"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "diana-theme-text-secondary text-center" }, [
              _vm._v("We'll send a recovery link to"),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "fieldset",
                  { attrs: { disabled: _vm.status.isLoading } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$store.state.username,
                          expression: "$store.state.username",
                        },
                      ],
                      ref: "firstElement",
                      staticClass: "input-lg mb-3 accessibility-element",
                      attrs: {
                        tabindex: "1",
                        placeholder: "Email",
                        disabled: "",
                      },
                      domProps: { value: _vm.$store.state.username },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$store.state,
                            "username",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.status.errorMessage
                      ? _c("ErrorMessage", {
                          staticClass: "mb-3",
                          model: {
                            value: _vm.status.errorMessage,
                            callback: function ($$v) {
                              _vm.$set(_vm.status, "errorMessage", $$v)
                            },
                            expression: "status.errorMessage",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-lg btn-primary w-100 mb-3 accessibility-element",
                        style: {
                          backgroundColor: _vm.$store.state.buttonColor,
                        },
                        attrs: { tabindex: "2", type: "submit" },
                      },
                      [
                        _vm.status.isLoading
                          ? _c("Spinner")
                          : _c("span", [_vm._v("Send a link")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("div", { staticClass: "d-inline-flex" }, [
          _c(
            "a",
            {
              staticClass: "diana-theme-text-primary accessibility-link",
              staticStyle: { cursor: "pointer" },
              attrs: { tabindex: "3" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openHelpScreen.apply(null, arguments)
                },
              },
            },
            [_vm._v("Need help?")]
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "mx-2", staticStyle: { "align-self": "center" } },
            [_vm._v("•")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "diana-theme-text-primary accessibility-link",
              attrs: { tabindex: "4", href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$store.commit("ovNavigation/navigate", "register")
                },
              },
            },
            [_vm._v("Create an account")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn back-arrow accessibility-element",
          attrs: { tabindex: "5", type: "button" },
          on: {
            click: function ($event) {
              return _vm.$store.commit("ovNavigation/navigate", "login")
            },
            blur: function ($event) {
              return _vm.$emit("last-element-blur", _vm.$refs.firstElement)
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M14.5825 6.5925H3.4125L8.2925 1.7125C8.6825 1.3225 8.6825 0.6825 8.2925 0.2925C7.9025 -0.0975 7.2725 -0.0975 6.8825 0.2925L0.2925 6.8825C-0.0975 7.2725 -0.0975 7.9025 0.2925 8.2925L6.8825 14.8825C7.2725 15.2725 7.9025 15.2725 8.2925 14.8825C8.6825 14.4925 8.6825 13.8625 8.2925 13.4725L3.4125 8.5925H14.5825C15.1325 8.5925 15.5825 8.1425 15.5825 7.5925C15.5825 7.0425 15.1325 6.5925 14.5825 6.5925Z",
                  fill: "var(--text-primary)",
                  "fill-opacity": "0.54",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }