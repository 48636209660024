import Vue from 'vue';
import Plugin from '@/Plugin.vue';
import store from '@/store';
import TextDivider from '@/components/TextDivider';
import LabeledCheckbox from '@/components/LabeledCheckbox';
import Spinner from '@/components/Spinner';
import ListItem from '@/components/ListItem';
import AlertMessage from '@/components/AlertMessage';
import UserAvatar from '@/components/UserAvatar';
import ToolTip from '@/components/ToolTip';
import ThemeSwitch from '@/components/ThemeSwitch';
import resource from '@/resource.config';

// global components
Vue.component('TextDivider', TextDivider);
Vue.component('LabeledCheckbox', LabeledCheckbox);
Vue.component('Spinner', Spinner);
Vue.component('ListItem', ListItem);
Vue.component('AlertMessage', AlertMessage);
Vue.component('UserAvatar', UserAvatar);
Vue.component('ToolTip', ToolTip);
Vue.component('ThemeSwitch', ThemeSwitch);

// global variables / methods
Vue.prototype.$resource = resource;
Vue.prototype.$request = function (options = {}) {
  options.url = options.url || '';
  if (!/^http(s|):\/\//.test(options.url))
    options.url = `${this.$store.state.apiBaseUrl}/${options.url.replace(/^\/+/, '')}`;
  return new Promise((resolve, reject) => {
    (window.$ || window.jQuery).ajax({
      cache: false,
      processData: false,
      xhrFields: {
        withCredentials: true,
      },
      ...options,
      success: resolve,
      error: (xHR) => {
        if (!xHR.status) reject(new Error('Network Error'));
        else {
          const { responseJSON = {} } = xHR;
          const text = /<\/?[a-z][\s\S]*>/im.test(xHR.responseText || '') ? '' : xHR.responseText;
          const error = new Error(
            responseJSON.message || text || `Server error with status ${xHR.status}`,
          );
          error.xHR = xHR;
          reject(error);
        }
      },
    });
  });
};
Vue.prototype.$verifyEmail = function (options = {}) {
  this.$store.state.active = false;
  this.$store.state.verifyEmail.retryUrl = options.retryUrl || '';
  this.$store.state.verifyEmail.email = options.email || '';
  this.$store.state.verifyEmail.name = options.name || '';
  this.$store.commit('ovNavigation/navigate', 'verifyEmail');
};

// mount plugin to jquery
(function ($) {
  const instanceMap = {};

  // rise-x login
  $.fn.riseXLogin = function (arg) {
    const instancePath = 'rx-login-instance';
    const handleCommand = (command, instance, element) => {
      switch (command) {
        case 'show':
          instance.$store.state.active = true;
          break;

        case 'hide':
          instance.$store.state.active = false;
          break;

        case 'destroy':
          destroyInstance(element);
          break;

        default:
          break;
      }
    };
    const handleOptions = (options, instance) => {
      const { state } = instance.$store;

      function setOption(key, fallback) {
        if (options[key]) {
          state[key] = options[key];
        } else if (fallback) {
          state[key] = fallback;
        }
      }

      setOption('position');
      setOption('show');
      setOption('apiBaseUrl', resource['api-base-url']);
      setOption('helpUrl', resource['help-url']);
      setOption('homeUrl', resource['rise-x-url']);
      setOption('buttonColor', resource['button-color']);
      setOption('navBarSelector');
      setOption('loginRedirectUrl');
      setOption('loginLogoSrc', resource['ecosystem-name']);
      setOption('ecosystemName');
      setOption('appTrayMountSelector');
      setOption('navigateUnauthorizedTo');

      if (Array.isArray(options.drawerApps))
        state.appDrawer.apps = options.drawerApps.map((app) => ({
          name: app.name || 'Unnamed',
          image: app.image || '',
          href: app.href || '/',
        }));
    };
    const createInstance = (element) => {
      const child = document.createElement('div');
      $(element).append(child);

      const instance = new Vue({ store, el: child, render: (h) => h(Plugin) });
      instanceMap[instance._uid] = instance;
      $(element).data(instancePath, instance._uid);

      return instance;
    };
    const destroyInstance = (element) => {
      const instance = getInstance(element);
      if (instance) {
        instance.$destroy();
        delete instanceMap[instance._uid];
        $(instance.$el).remove();
      }
    };
    const getInstance = (element, create = false) => {
      const id = $(element).data(instancePath);
      return instanceMap[id] || (create ? createInstance(element) : null);
    };

    return this.each(function () {
      const instance = getInstance(this, true);

      if (typeof arg === 'string') handleCommand(arg, instance, this);
      if (typeof arg === 'object') handleOptions(arg, instance, this);

      return this;
    });
  };
})(window.jQuery);
