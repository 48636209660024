<template>
  <label>
    <input type="checkbox" :checked="value" @input="$emit('input', $event.target.checked)" />
    <span></span>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'LabeledCheckbox',
  props: ['value'],
};
</script>

<style lang="scss" scoped>
label {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2.5em;
  color: rgba(white, 0.5);
  user-select: none;
  line-height: 1.4em;

  &:before {
    content: '';
    display: block;
    position: absolute;
    border: 2px solid $primary;
    border-radius: 0.3em;
    width: 1.4em;
    height: 1.4em;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  input[type='checkbox'] {
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    width: 1.2em;
    transform: translateY(-50%);
    visibility: hidden;

    &:checked + span {
      display: block;
      position: absolute;
      width: 0.8em;
      height: 0.4em;
      left: 0.3em;
      top: 0.45em;
      border: 2.5px solid #17b0b1;
      border-top-width: 0;
      border-right-width: 0;
      transform: rotate(-45deg);
      cursor: pointer;
    }
  }
}
</style>
