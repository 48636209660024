var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative p-4" }, [
    _c(
      "div",
      [
        _vm.$store.state.loginLogoSrc
          ? _c("div", { staticClass: "text-center pb-4" }, [
              _c("img", {
                staticClass: "logo logo-header",
                attrs: {
                  src: _vm.$store.state.loginLogoSrc,
                  alt: "Login Logo",
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass: "align-left mb-3 diana-theme-text-secondary",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c("img", {
                staticClass: "logo logo-inner mr-2",
                attrs: { src: _vm.$resource["key-icon"], alt: "google" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("Single sign on (SSO):")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              ref: "firstElement",
              staticClass:
                "btn-lg btn-secondary w-100 mb-3 accessibility-element",
              attrs: { tabindex: "1", type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loginWithMicrosoft.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "logo logo-button",
                attrs: {
                  src: _vm.$resource["microsoft-360-logo"],
                  alt: "Microsoft Logo",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "align-middle ml-2" }, [
                _vm._v("Continue with Microsoft"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-lg btn-secondary w-100 accessibility-element",
              attrs: { tabindex: "2", type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loginWithGoogle.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "logo logo-button",
                attrs: {
                  src: _vm.$resource["google-logo"],
                  alt: "Google Logo",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "align-middle ml-2" }, [
                _vm._v("Continue with Google"),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("OrDivider"),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "mt-1",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "fieldset",
              { attrs: { disabled: _vm.status.isLoading } },
              [
                _vm.status.successMessage
                  ? _c("p", {
                      staticClass: "mb-3",
                      style: { color: _vm.$store.state.buttonColor },
                      domProps: {
                        textContent: _vm._s(_vm.status.successMessage),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "position-relative" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.Email,
                        expression: "formData.Email",
                      },
                    ],
                    staticClass:
                      "input-lg text-left mb-3 accessibility-element",
                    attrs: {
                      tabindex: "3",
                      type: "email",
                      autocomplete: "email",
                      autocapitalize: "none",
                      autocorrect: "off",
                      spellcheck: "off",
                      required: "required",
                      placeholder: "Email address",
                      disabled: _vm.status.isSsoChecked,
                    },
                    domProps: { value: _vm.formData.Email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "Email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.status.isSsoChecked,
                          expression: "status.isSsoChecked",
                        },
                      ],
                      staticClass: "btn end-icon",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearEmail()
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "12",
                            height: "11",
                            viewBox: "0 0 12 11",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M10.9734 2.20726L9.14007 0.373972C8.9008 0.149225 8.58727 0.0202686 8.25911 0.0116329C7.93095 0.00299726 7.61107 0.115285 7.36031 0.327137L1.33858 6.34887C1.12231 6.56697 0.98765 6.85282 0.957204 7.15846L0.669499 9.94853C0.660486 10.0465 0.673202 10.1453 0.706741 10.2378C0.74028 10.3303 0.793816 10.4143 0.863533 10.4838C0.926052 10.5458 1.0002 10.5949 1.08172 10.6282C1.16324 10.6615 1.25052 10.6783 1.33858 10.6778H1.3988L4.18887 10.4236C4.4945 10.3931 4.78036 10.2585 4.99846 10.0422L11.0202 4.02047C11.2539 3.77355 11.3802 3.44406 11.3714 3.10418C11.3627 2.76431 11.2195 2.44177 10.9734 2.20726ZM4.06843 9.08541L2.06119 9.27275L2.24184 7.26551L6.02215 3.53204L7.82867 5.33856L4.06843 9.08541ZM8.69847 4.44199L6.90534 2.64885L8.21005 1.31069L10.0366 3.13728L8.69847 4.44199Z",
                              fill: "var(--text-primary)",
                              "fill-opacity": "0.54",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.status.isSsoChecked
                  ? _c("div", { staticClass: "position-relative" }, [
                      this.passwordType === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.Password,
                                expression: "formData.Password",
                              },
                            ],
                            staticClass:
                              "input-lg mb-3 text-left accessibility-element",
                            attrs: {
                              tabindex: "4",
                              autofocus: "autofocus",
                              autocomplete: "current-password",
                              autocapitalize: "none",
                              autocorrect: "off",
                              required: "required",
                              placeholder: "Password",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.formData.Password)
                                ? _vm._i(_vm.formData.Password, null) > -1
                                : _vm.formData.Password,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.formData.Password,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formData,
                                        "Password",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formData,
                                        "Password",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.formData, "Password", $$c)
                                }
                              },
                            },
                          })
                        : this.passwordType === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.Password,
                                expression: "formData.Password",
                              },
                            ],
                            staticClass:
                              "input-lg mb-3 text-left accessibility-element",
                            attrs: {
                              tabindex: "4",
                              autofocus: "autofocus",
                              autocomplete: "current-password",
                              autocapitalize: "none",
                              autocorrect: "off",
                              required: "required",
                              placeholder: "Password",
                              type: "radio",
                            },
                            domProps: {
                              checked: _vm._q(_vm.formData.Password, null),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.formData, "Password", null)
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.Password,
                                expression: "formData.Password",
                              },
                            ],
                            staticClass:
                              "input-lg mb-3 text-left accessibility-element",
                            attrs: {
                              tabindex: "4",
                              autofocus: "autofocus",
                              autocomplete: "current-password",
                              autocapitalize: "none",
                              autocorrect: "off",
                              required: "required",
                              placeholder: "Password",
                              type: this.passwordType,
                            },
                            domProps: { value: _vm.formData.Password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "Password",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn end-icon",
                          attrs: { type: "button" },
                          on: { click: _vm.toggle },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "18",
                                height: "14",
                                viewBox: "0 0 14 12",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z",
                                  fill: _vm.fillColor,
                                  "fill-opacity": "0.54",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.status.isSsoChecked
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-end w-100 mb-3" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "diana-theme-text-primary accessibility-link",
                            attrs: { tabindex: "5", href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$store.commit(
                                  "ovNavigation/navigate",
                                  "forgotPassword"
                                )
                              },
                            },
                          },
                          [_vm._v("Forgot password?")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.status.errorMessage
                  ? _c("ErrorMessage", {
                      staticClass: "mb-3",
                      model: {
                        value: _vm.status.errorMessage,
                        callback: function ($$v) {
                          _vm.$set(_vm.status, "errorMessage", $$v)
                        },
                        expression: "status.errorMessage",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.status.isSsoChecked
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn-lg btn-primary w-100 mb-3 accessibility-element",
                        style: {
                          backgroundColor: _vm.$store.state.buttonColor,
                        },
                        attrs: {
                          tabindex: _vm.formData.Password.length === 0 ? -1 : 6,
                          type: "submit",
                          disabled: _vm.formData.Password.length === 0,
                        },
                      },
                      [
                        _vm.status.isLoading
                          ? _c("Spinner")
                          : _c("span", [_vm._v("Login")]),
                      ],
                      1
                    )
                  : _c(
                      "button",
                      {
                        staticClass:
                          "btn-lg btn-primary w-100 mb-3 accessibility-element",
                        style: {
                          backgroundColor: _vm.$store.state.buttonColor,
                        },
                        attrs: {
                          tabindex: _vm.formData.Email.length === 0 ? -1 : 6,
                          type: "submit",
                          disabled: _vm.formData.Email.length === 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.checkSso.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.status.isLoading
                          ? _c("Spinner")
                          : _c("span", [_vm._v("Continue")]),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("div", { staticClass: "d-inline-flex" }, [
            _c(
              "a",
              {
                staticClass: "diana-theme-text-primary accessibility-link",
                staticStyle: { cursor: "pointer" },
                attrs: { tabindex: "7" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openHelpScreen.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Need help?")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "mx-2 diana-theme-text-primary",
                staticStyle: { "align-self": "center" },
              },
              [_vm._v("•")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "diana-theme-text-primary accessibility-link",
                attrs: { tabindex: "8", href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.navigateToRegister.apply(null, arguments)
                  },
                  blur: function ($event) {
                    return _vm.$emit(
                      "last-element-blur",
                      _vm.$refs.firstElement
                    )
                  },
                },
              },
              [_vm._v("Create an account")]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }