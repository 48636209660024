<template>
  <div ref="AppTray" v-show="value" class="_app-tray py-3">
    <div class="px-3 mb-2">
      <h6 class="m-0 text-muted text-uppercase">Switch to</h6>
    </div>

    <a
      v-for="app in $store.state.appDrawer.apps"
      :href="app.href"
      :key="app.name"
      class="_item px-3 py-2"
    >
      <div class="_item-image-wrapper">
        <img :src="app.image" :alt="app.image" />
      </div>
      {{ app.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppTrayCard',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mouseEventDownTarget: null,
  }),
  created() {
    window.addEventListener('mousedown', this.windowMousedownHandler);
    window.addEventListener('mouseup', this.windowMouseupHandler);
    window.addEventListener('scroll', this.windowScrollHandler);
  },
  destroyed() {
    window.removeEventListener('mousedown', this.windowMousedownHandler);
    window.removeEventListener('mouseup', this.windowMouseupHandler);
    window.removeEventListener('scroll', this.windowScrollHandler);
  },
  methods: {
    isWithinTheComponent(element) {
      const mainWrapper = this.$refs.AppTray;
      if (mainWrapper) {
        const isWrapper = mainWrapper === element;
        const isWrapperChild = mainWrapper.contains && mainWrapper.contains(element);
        return isWrapper || isWrapperChild;
      }
      return false;
    },
    windowMousedownHandler(e) {
      if (this.value && !this.isWithinTheComponent(e.target)) this.mouseEventDownTarget = e.target;
    },
    windowMouseupHandler(e) {
      if (this.mouseEventDownTarget === e.target)
        setTimeout(() => {
          if (this.value && !this.isWithinTheComponent(e.target)) this.$emit('input', false);
        });
      this.mouseEventDownTarget = null;
    },
  },
};
</script>

<style lang="scss" scoped>
._app-tray {
  color: white;
  background-color: $bg;
  border-radius: $border-radius;
  width: 300px;
  max-width: 90vw;

  ._item {
    display: flex;
    color: white;
    align-items: center;
  }

  ._item-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.05);
    margin-right: 10px;
    border-radius: 5px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
