var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.userData
        ? [
            _c(
              "ListItem",
              {
                staticClass: "py-3 _divider-b",
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.$store.state.active = false
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("UserAvatar", {
                      staticStyle: { width: "40px", height: "40px" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "pl-3" }, [
                      _c("h6", { staticClass: "mt-0 mb-1" }, [
                        _vm._v(_vm._s(_vm.userData.user.displayName)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "m-0 small text-muted" }, [
                        _vm._v(_vm._s(_vm.userData.user.email)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.menuItems, function (item) {
              return _c(
                "ListItem",
                {
                  key: item.id,
                  class: { _active: item.id === _vm.activeMenuItemId },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.openMenu(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("div", { staticClass: "pr-2 h5 mb-0" }, [
                      _c("i", { class: "rxi-" + item.icon }),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("h6", { staticClass: "m-0" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "ListItem",
              {
                staticClass: "_divider-t",
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.logout.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c(
                    "div",
                    { staticClass: "pr-2 h5 mb-0" },
                    [
                      _vm.isLoggingOut
                        ? _c("Spinner")
                        : _c("i", { staticClass: "rxi-logout" }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("h6", { staticClass: "m-0" }, [_vm._v("Signout")]),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }