var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "_header d-flex align-items-center justify-content-between pt-2 pb-2 pl-3 pr-3",
    },
    [
      _vm.$store.state.loginLogoSrc
        ? _c("img", {
            staticClass: "logo logo-footer",
            attrs: {
              src: _vm.$store.state.loginLogoSrc,
              alt: "Ecosystem Logo",
            },
            on: { click: _vm.handleLogoClick },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }