var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "app-drawer-btn", attrs: { type: "button" } },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "22",
            height: "21",
            viewBox: "0 0 22 21",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
            _c("path", {
              attrs: {
                d: "M3.4375 0H6.70312C8.59856 0 10.1406 1.41698 10.1406 3.15868V6.15943C10.1406 7.90112 8.59856 9.31811 6.70312 9.31811H3.4375C1.54206 9.31811 0 7.90112 0 6.15943V3.15868C0 1.41698 1.54206 0 3.4375 0ZM1.71875 6.15943C1.71875 7.03028 2.48978 7.73877 3.4375 7.73877H6.70312C7.65084 7.73877 8.42188 7.03028 8.42188 6.15943V3.15868C8.42188 2.28783 7.65084 1.57934 6.70312 1.57934H3.4375C2.48978 1.57934 1.71875 2.28783 1.71875 3.15868V6.15943Z",
                fill: "#07B999",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M15.2969 0H18.5625C20.4579 0 22 1.41698 22 3.15868V6.15943C22 7.90112 20.4579 9.31811 18.5625 9.31811H15.2969C13.4014 9.31811 11.8594 7.90112 11.8594 6.15943V3.15868C11.8594 1.41698 13.4014 0 15.2969 0ZM13.5781 6.15943C13.5781 7.03028 14.3492 7.73877 15.2969 7.73877H18.5625C19.5102 7.73877 20.2812 7.03028 20.2812 6.15943V3.15868C20.2812 2.28783 19.5102 1.57934 18.5625 1.57934H15.2969C14.3492 1.57934 13.5781 2.28783 13.5781 3.15868V6.15943Z",
                fill: "#07B999",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M15.2969 10.8975H18.5625C20.4579 10.8975 22 12.3144 22 14.0561V17.0569C22 18.7986 20.4579 20.2156 18.5625 20.2156H15.2969C13.4014 20.2156 11.8594 18.7986 11.8594 17.0569V14.0561C11.8594 12.3144 13.4014 10.8975 15.2969 10.8975ZM13.5781 17.0569C13.5781 17.9277 14.3492 18.6362 15.2969 18.6362H18.5625C19.5102 18.6362 20.2812 17.9277 20.2812 17.0569V14.0561C20.2812 13.1853 19.5102 12.4768 18.5625 12.4768H15.2969C14.3492 12.4768 13.5781 13.1853 13.5781 14.0561V17.0569Z",
                fill: "#07B999",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M0.859375 16.2672C1.33401 16.2672 1.71875 16.6208 1.71875 17.0569C1.71875 17.9277 2.48978 18.6362 3.4375 18.6362H6.70312C7.65084 18.6362 8.42188 17.9277 8.42188 17.0569V14.0561C8.42188 13.1853 7.65084 12.4768 6.70312 12.4768H3.4375C2.75408 12.4768 2.1355 12.8487 1.86162 13.4242C1.67148 13.8239 1.16475 14.0061 0.72991 13.8314C0.295023 13.6566 0.0966787 13.1911 0.286816 12.7915C0.834367 11.6409 2.07109 10.8975 3.4375 10.8975H6.70312C8.59856 10.8975 10.1406 12.3144 10.1406 14.0561V17.0569C10.1406 18.7986 8.59856 20.2156 6.70312 20.2156H3.4375C1.54206 20.2156 0 18.7986 0 17.0569C0 16.6208 0.384742 16.2672 0.859375 16.2672Z",
                fill: "#07B999",
              },
            }),
          ]),
          _vm._v(" "),
          _c("defs", [
            _c("clipPath", { attrs: { id: "clip0" } }, [
              _c("rect", {
                attrs: {
                  width: "22",
                  height: "20.2156",
                  fill: "white",
                  transform: "matrix(-1 0 0 1 22 0)",
                },
              }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }