var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c("div", { staticClass: "_alert-message d-flex" }, [
        _c(
          "div",
          { staticClass: "flex-fill" },
          _vm._l(_vm.messages, function (message, index) {
            return _c("div", { key: index }, [_vm._v(_vm._s(message))])
          }),
          0
        ),
        _vm._v(" "),
        _vm.$listeners.input
          ? _c("div", [
              _c(
                "span",
                {
                  staticClass: "_close",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("input", "")
                    },
                  },
                },
                [_vm._v(" ⨯ ")]
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }