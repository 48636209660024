const appDrawer = {
  state: {
    show: false,
    apps: [
    ],
  },
  namespaced: true,
};

export default appDrawer;
