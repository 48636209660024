<template>
  <div class="position-relative p-4">
    <!-- register logo -->
    <div v-if="$store.state.loginLogoSrc" class="text-center pb-4">
      <img class="logo logo-header" :src="$store.state.loginLogoSrc" alt="Login Logo" />
    </div>
    <div class="text-center my-4">
      <h3 class="mb-1 fs-4 fw-500">Welcome to Rise-x</h3>
      <span class="_welcome-subtitle mb-0 fs-2 fw-300"> Let's set up your account </span>
    </div>

    <!-- register options -->
    <div>
      <div
        class="align-left mb-3 diana-theme-text-secondary"
        style="display: flex; align-items: center"
      >
        <img :src="$resource['key-icon']" alt="google" class="logo logo-inner mr-2" />
        <span>Single sign on (SSO):</span>
      </div>
      <button
        ref="firstElement"
        tabindex="1"
        type="button"
        class="btn-lg btn-secondary w-100 mb-3 accessibility-element"
        @click.prevent="registerWithMicrosoft"
      >
        <img :src="$resource['microsoft-360-logo']" alt="AzureAD" class="logo logo-button" />
        <span class="align-middle ml-2">Continue with Microsoft</span>
      </button>

      <button
        tabindex="2"
        type="button"
        class="btn-lg btn-secondary w-100 accessibility-element"
        @click.prevent="registerWithGoogle"
      >
        <img :src="$resource['google-logo']" alt="google" class="logo logo-button" />
        <span class="align-middle ml-2">Continue with Google</span>
      </button>
    </div>

    <OrDivider />

    <!-- register form -->
    <form @submit.prevent="submit" class="mt-1" novalidate>
      <!-- server error -->
      <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="mb-3" />
      <fieldset :disabled="status.isLoading">
        <!-- name input -->
        <div class="position-relative">
          <input
            tabindex="3"
            id="frmName"
            v-model="formData.name"
            type="text"
            autocomplete="name"
            autocapitalize="on"
            autocorrect="on"
            spellcheck="on"
            class="input-lg mb-3 accessibility-element"
            placeholder="Full name"
          />
        </div>
        <!-- email input -->
        <div class="position-relative">
          <input
            tabindex="4"
            id="frmEmail"
            v-model="formData.email"
            type="email"
            autocomplete="username"
            autocapitalize="none"
            autocorrect="off"
            spellcheck="off"
            class="input-lg mb-3 accessibility-element"
            required
            placeholder="Email address"
          />
        </div>

        <!-- password input -->
        <div class="d-flex mb-3">
          <div class="flex-fill position-relative">
            <input
              tabindex="5"
              id="frmPassword"
              v-model="formData.password"
              :type="this.passwordType"
              autocomplete="new-password"
              class="input-lg mb-0 password-strength accessibility-element"
              required="required"
              placeholder="Password"
            />
            <!-- password strength -->
            <PasswordStrength :value="formData.password" v-on:isValid="isPasswordValid" />
            <button type="button" class="btn end-icon" @click="toggle">
              <svg
                width="18"
                height="14"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z"
                  :fill="fillColor"
                  fill-opacity="0.54"
                />
              </svg>
            </button>
          </div>
        </div>

        <!-- validation error -->
        <ErrorMessage v-if="!!formErrorMessages.length" v-model="formErrorMessages" class="mb-3" />

        <!-- submit button -->
        <button
          :tabindex="this.isValid() ? 6 : -1"
          type="submit"
          class="btn btn-primary btn-lg w-100 mb-3 accessibility-element"
          :style="{ backgroundColor: $store.state.buttonColor }"
          :disabled="this.isValid() === false"
        >
          <Spinner v-if="status.isLoading" />
          <span v-else>Register</span>
        </button>

        <!-- footer text -->
        <p class="text-center mb-0">
          Already have an account?
          <a
            tabindex="7"
            href=""
            class="diana-theme-text-primary accessibility-link"
            @click.prevent="navigateToLogin"
            @blur="$emit('last-element-blur', $refs.firstElement)"
            >Login
          </a>
        </p>
      </fieldset>
    </form>

    <!-- disclaimer -->
    <!-- <p class="text-center pt-3 _privacy-text">
      By clicking Register, you accept our
      <a :href="$resource['terms-url']" class="diana-theme-text-primary" target="_blank"
        >Terms of Service</a>,
      <a :href="$resource['privacy-url']" class="diana-theme-text-primary" target="_blank"
        >Privacy Policy.</a
      >
    </p> -->
  </div>
</template>

<script>
import PasswordStrength from '@/components/PasswordStrength';
import querystring from 'querystring';
import { object, string } from 'yup';
import OrDivider from '../../components/OrDivider.vue';
import ErrorMessage from '@/components/ErorrMessage.vue';

const registerSchema = object({
  name: string().required('Name is required.').max(64, 'Name must be less than 64 characters.'),
  email: string()
    .required('Email is required.')
    .email('Email is invalid.')
    .max(64, 'Email must be less than 64 characters.'),
  password: string()
    .required('Password is required.')
    .min(8, 'Password must be more than 8 characters.')
    .max(64, 'Password must be less than 64 characters.'),
});

export default {
  components: {
    ErrorMessage,
    PasswordStrength,
    OrDivider,
  },
  data: () => ({
    formData: {
      name: '',
      email: '',
      password: '',
      redirectUrl: window.location.href,
    },
    status: {
      isLoading: false,
      errorMessage: '',
    },
    formErrorMessages: [],
    valid: false,
    passwordType: 'password',
    fillColor: 'var(--text-primary)',
  }),
  computed: {
    userEmail() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);
      const returnUrl = query['ReturnUrl'] || '';
      const returnUrlQuery = querystring.decode(returnUrl);
      return returnUrlQuery['email'] || '';
    },
  },
  created() {
    if (this.userEmail) {
      this.formData.email = this.userEmail;
    }
  },
  methods: {
    isPasswordValid(valid) {
      this.valid = valid;
    },
    formMessages() {
      const messages = [];

      try {
        registerSchema.validateSync(
          {
            name: this.formData.name,
            email: this.formData.email,
            password: this.formData.password,
          },
          {
            abortEarly: false,
          },
        );
      } catch (e) {
        // sometimes there is only 1 error message
        if (!e.inner && e.message) {
          messages.push(e.message);
        }

        if (Array.isArray(e.inner)) {
          e.inner.forEach((e) => messages.push(e.message));
        }
      }

      return messages;
    },
    isValid() {
      return this.valid;
    },
    toggle() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      this.fillColor =
        this.passwordType === 'text' ? this.$store.state.buttonColor : 'var(--text-primary)';
    },
    async submit() {
      this.formErrorMessages = [];
      const formMessages = this.formMessages();
      if (formMessages.length > 0) {
        this.formErrorMessages = formMessages;
        return;
      }
      if (this.isValid() === false) return;

      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        const formData = new FormData();
        formData.append('Name', this.formData.name);
        formData.append('Email', this.formData.email);
        formData.append('Password', this.formData.password);
        formData.append('RedirectUrl', this.formData.redirectUrl);

        const data = await this.$request({
          url: '/Account/Register',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        if (data.registered)
          this.$verifyEmail({
            retryUrl: data.retryUrl,
            email: this.formData.email,
            name: this.formData.name,
          });
        else throw new Error('Unable to create an account.');
      } catch (e) {
        this.status.errorMessage = e.message;
        const data = e.xHR && e.xHR.responseJSON;
        if (Array.isArray(data)) {
          const msg = data.map((a) => a.description || '').join('\n');
          if (msg) this.status.errorMessage = msg;
        }
      } finally {
        this.status.isLoading = false;
      }
    },
    registerWithMicrosoft() {
      const query = {
        provider: 'aad',
        returnUrl:
          this.appendAnonIdToUrl(this.$store.state.loginRedirectUrl) || window.location.href,
      };
      window.location.href = `${
        this.$store.state.apiBaseUrl
      }/External/Challenge?${querystring.encode(query)}`;
    },
    registerWithGoogle() {
      const query = {
        provider: 'Google',
        returnUrl:
          this.appendAnonIdToUrl(this.$store.state.loginRedirectUrl) || window.location.href,
      };
      window.location.href = `${
        this.$store.state.apiBaseUrl
      }/External/Challenge?${querystring.encode(query)}`;
    },
    appendAnonIdToUrl(url) {
      const currentUrl = new URL(window.location.href);
      const anonId = currentUrl.searchParams.get('anonId');

      if (!anonId) {
        return url;
      }

      const updatedUrl = new URL(url);
      updatedUrl.searchParams.set('anonId', anonId);

      return updatedUrl.href;
    },
    navigateToLogin() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);

      query['plugin-rx-action'] = 'login';

      const newQueryString = querystring.encode(query);

      const newURL = `${window.location.pathname}?${newQueryString}`;

      window.history.replaceState({}, '', newURL);

      this.$store.commit('ovNavigation/navigate', 'login');
    },
  },
};
</script>

<style lang="scss" scoped>
// ._privacy-text {
//   position: fixed;
//   bottom: 50px;
//   right: 0;
//   width: 100%;
//   color: var(--text-secondary);
//   @media (max-height: 667px) {
//     bottom: 0px;
//   }
// }

._welcome-subtitle {
  color: var(--text-secondary);
}
</style>
