<template>
  <div class="_item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
};
</script>

<style lang="scss" scoped>
._item {
  padding: map_get($spacers, 2) map_get($spacers, 4);
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: rgba($primary, 0.2);
  }

  &:active,
  &._active {
    background-color: rgba($primary, 0.3);
  }

  &._divider-b {
    border-bottom: 1px solid rgba(white, 0.15);
  }
  &._divider-t {
    border-top: 1px solid rgba(white, 0.15);
  }
}
</style>
