<template>
  <div>
    <template v-if="userData">
      <ListItem class="py-3 _divider-b" @click.native.prevent="$store.state.active = false">
        <div class="d-flex align-items-center">
          <UserAvatar style="width: 40px; height: 40px" />
          <div class="pl-3">
            <h6 class="mt-0 mb-1">{{ userData.user.displayName }}</h6>
            <p class="m-0 small text-muted">{{ userData.user.email }}</p>
          </div>
        </div>
      </ListItem>

      <ListItem
        v-for="item in menuItems"
        :key="item.id"
        :class="{ _active: item.id === activeMenuItemId }"
        @click.native.prevent="openMenu(item)"
      >
        <div class="d-flex align-items-center">
          <div class="pr-2 h5 mb-0">
            <i :class="'rxi-' + item.icon"></i>
          </div>
          <div>
            <h6 class="m-0">{{ item.title }}</h6>
          </div>
        </div>
      </ListItem>

      <ListItem class="_divider-t" @click.native.prevent="logout">
        <div class="d-flex align-items-center">
          <div class="pr-2 h5 mb-0">
            <Spinner v-if="isLoggingOut" />
            <i v-else class="rxi-logout"></i>
          </div>
          <div>
            <h6 class="m-0">Signout</h6>
          </div>
        </div>
      </ListItem>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UserProfile',
  data: () => ({
    isLoggingOut: false,
    menuItems: [
      {
        id: 'profile',
        title: 'Profile',
        icon: 'address-card-o',
      },
      {
        id: 'security',
        title: 'Security',
        icon: 'key',
      },
      {
        id: 'settings',
        title: 'Settings',
        icon: 'cog',
      },
      {
        id: 'company',
        title: 'Company',
        icon: 'cube',
      },
    ],
  }),
  computed: {
    ...mapState('userProfile', {
      userData: 'data',
    }),
    activeMenuItemId() {
      const { extensionPayload } = this.$store.state.navigation;
      return extensionPayload ? extensionPayload.id : null;
    },
  },
  methods: {
    openMenu(menuItem) {
      const { navigation } = this.$store.state;
      navigation.extensionPayload = menuItem.id === this.activeMenuItemId ? null : menuItem;
    },
    async logout() {
      if (this.isLoggingOut) return;
      try {
        this.isLoggingOut = true;
        await this.$request({
          url: '/Account/SignOut',
          method: 'GET',
        });
        this.$store.state.active = false;
      } catch (e) {
        console.error('Logout Error', e);
      } finally {
        this.isLoggingOut = false;
        this.$store.dispatch('userProfile/loadProfile', { vm: this });
      }
    },
  },
};
</script>

<style scoped></style>
