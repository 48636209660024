var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_tooltip-wrapper" },
    [
      _c("ToolTip", [
        _c(
          "div",
          { staticClass: "pr-3 pl-3 pt-2 pb-2" },
          _vm._l(_vm._levels, function (level, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "d-flex align-items-center mb-2 mt-2",
              },
              [
                level.isValid
                  ? _c("div", { staticClass: "mr-2 d-flex" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "12",
                            height: "9",
                            viewBox: "0 0 12 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M4.12841 7.295L1.79508 4.96167C1.53508 4.70167 1.12175 4.70167 0.861748 4.96167C0.601748 5.22167 0.601748 5.635 0.861748 5.895L3.65508 8.68833C3.91508 8.94833 4.33508 8.94833 4.59508 8.68833L11.6617 1.62833C11.9217 1.36833 11.9217 0.955 11.6617 0.695C11.4017 0.435 10.9884 0.435 10.7284 0.695L4.12841 7.295Z",
                              fill: "#26BF63",
                            },
                          }),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "mr-2 d-flex" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "10",
                            height: "11",
                            viewBox: "0 0 10 11",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M5.80926 5.5L9.91082 0.610938C9.97957 0.529688 9.92176 0.40625 9.81551 0.40625H8.56863C8.49519 0.40625 8.42488 0.439062 8.37644 0.495312L4.99363 4.52813L1.61082 0.495312C1.56394 0.439062 1.49363 0.40625 1.41863 0.40625H0.171756C0.0655061 0.40625 0.00769351 0.529688 0.0764435 0.610938L4.17801 5.5L0.0764435 10.3891C0.0610429 10.4072 0.0511628 10.4293 0.0479759 10.4529C0.0447889 10.4764 0.048429 10.5004 0.0584644 10.522C0.0684997 10.5435 0.0845088 10.5617 0.104591 10.5745C0.124672 10.5872 0.147983 10.5939 0.171756 10.5938H1.41863C1.49207 10.5938 1.56238 10.5609 1.61082 10.5047L4.99363 6.47188L8.37644 10.5047C8.42332 10.5609 8.49363 10.5938 8.56863 10.5938H9.81551C9.92176 10.5938 9.97957 10.4703 9.91082 10.3891L5.80926 5.5Z",
                              fill: "#F95430",
                            },
                          }),
                        ]
                      ),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "font-size-base": true,
                      "font-weight-normal": true,
                      "flex-fill": true,
                      "diana-theme-text-secondary": !level.isValid,
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(level.description) + "\n        "
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }