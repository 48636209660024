var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("fieldset", { attrs: { disabled: _vm.status.isLoading } }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [
                _vm.status.isLoading
                  ? _c("Spinner")
                  : _c("span", [_vm._v("Save Changes")]),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c("AlertMessage", {
              staticClass: "mb-5 bg-danger",
              model: {
                value: _vm.status.errorMessage,
                callback: function ($$v) {
                  _vm.$set(_vm.status, "errorMessage", $$v)
                },
                expression: "status.errorMessage",
              },
            }),
            _vm._v(" "),
            _c("AlertMessage", {
              staticClass: "mb-5 bg-success",
              model: {
                value: _vm.status.successMessage,
                callback: function ($$v) {
                  _vm.$set(_vm.status, "successMessage", $$v)
                },
                expression: "status.successMessage",
              },
            }),
            _vm._v(" "),
            _vm._m(1),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-fill" }, [
      _c("h1", { staticClass: "m-0" }, [_vm._v("Settings/Preferences")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("label", { staticClass: "small" }, [_vm._v("Preferred language")]),
        _vm._v(" "),
        _c("input", { attrs: { type: "text" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("label", { staticClass: "small" }, [_vm._v("Date pattern")]),
        _vm._v(" "),
        _c("input", { attrs: { type: "text" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("label", { staticClass: "small" }, [_vm._v("Time pattern")]),
        _vm._v(" "),
        _c("input", { attrs: { type: "text" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("label", { staticClass: "small" }, [_vm._v("Time zone")]),
        _vm._v(" "),
        _c("input", { attrs: { type: "text" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }