<template>
  <div class="_overlay d-flex flex-column">
    <Header/>
    <div class="flex-fill d-flex align-items-center justify-content-center p-3">
      <div class="_content" :style="cssOverlayVars">
        <slot />
      </div>
    </div>
    <footer v-if="isRegisterPage()">
      <p class="text-center pt-3 _privacy-text">
        By clicking Register, you accept our
        <a :href="$resource['terms-url']" class="diana-theme-text-primary" target="_blank"
          >Terms of Service</a>,
        <a :href="$resource['privacy-url']" class="diana-theme-text-primary" target="_blank"
          >Privacy Policy.</a
        >
      </p>
    </footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import querystring from 'querystring';

export default {
  name: 'OverlayView',
  components: { Header },
  data: () => ({
    accessibilityColor: '#26BF63',
  }),
  mounted() {
    this.accessibilityColor = this.$store.state.buttonColor;
  },
  computed: {
    cssOverlayVars() {
      return {
        '--accessibility-color': `${this.accessibilityColor}35`, //35 is a transparency %
      }
    }
  },
  methods: {
    isRegisterPage() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);
      if(query['plugin-rx-action'] === 'register') {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
._privacy-text {
  color: var(--text-secondary);
  margin-bottom: 40px !important;
}


._overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--background-default);
  z-index: $zindex-modal-backdrop;
  color: var(--text-primary);
  overflow: auto;
}

._content {
  width: 100%;
  background-color: var(--background-paper);
  border: var(--overlay-border);
  border-radius: $border-radius;
  max-height: 100%;
  padding: 0;
  @media (min-width: 768px) {
    max-width: 512px;
    padding: 0.5em;
  }

  input.accessibility-element {
    &:focus {
      caret-color: v-bind(accessibilityColor);
      border-color: v-bind(accessibilityColor);
      box-shadow: none;
    }
  }

  .accessibility-element {
    &:focus {
      outline: none;
      box-shadow:
        0 0 0 4px var(--accessibility-color),
        0 1px 2px 0 #2F33360D;
    }
  }

  .accessibility-link {
    @extend .accessibility-element;
    padding: 4px;
    border-radius: 2px;
  }

  input.accessibility-element {
    &:focus {
      caret-color: v-bind(accessibilityColor);
      border-color: v-bind(accessibilityColor);
      box-shadow: none;
    }
  }

  .accessibility-element {
    &:focus {
      outline: none;
      box-shadow:
        0 0 0 4px var(--accessibility-color),
        0 1px 2px 0 #2F33360D;
    }
  }

  .accessibility-link {
    @extend .accessibility-element;
    padding: 4px;
    border-radius: 2px;
  }
}
</style>
