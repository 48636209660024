var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("fieldset", { attrs: { disabled: _vm.status.isLoading } }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [
                _vm.status.isLoading
                  ? _c("Spinner")
                  : _c("span", [_vm._v("Save Changes")]),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c("AlertMessage", {
              staticClass: "mb-5 bg-danger",
              model: {
                value: _vm.status.errorMessage,
                callback: function ($$v) {
                  _vm.$set(_vm.status, "errorMessage", $$v)
                },
                expression: "status.errorMessage",
              },
            }),
            _vm._v(" "),
            _c("AlertMessage", {
              staticClass: "mb-5 bg-success",
              model: {
                value: _vm.status.successMessage,
                callback: function ($$v) {
                  _vm.$set(_vm.status, "successMessage", $$v)
                },
                expression: "status.successMessage",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("label", { staticClass: "small" }, [_vm._v("Company name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.displayName,
                      expression: "formData.displayName",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.formData.displayName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "displayName", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("label", { staticClass: "small" }, [
                  _vm._v("Company address"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.address,
                      expression: "formData.address",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.formData.address },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "address", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("label", { staticClass: "small" }, [
                  _vm._v("Company number"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.companyNumber,
                      expression: "formData.companyNumber",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.formData.companyNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.formData,
                        "companyNumber",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("label", { staticClass: "small" }, [
                  _vm._v("Company short code"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.shortCode,
                      expression: "formData.shortCode",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.formData.shortCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "shortCode", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("label", { staticClass: "small" }, [
                  _vm._v("Company bank details"),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.bankAccount,
                      expression: "formData.bankAccount",
                    },
                  ],
                  attrs: { rows: "4" },
                  domProps: { value: _vm.formData.bankAccount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "bankAccount", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-fill" }, [
      _c("h1", { staticClass: "m-0" }, [_vm._v("Company")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }