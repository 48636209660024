<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style lang="scss" scoped>
.loader {
  display: inline-block;
  border: 0.2em solid rgba(white, 0.5);
  border-top: 0.2em solid white;
  border-radius: 5em;
  width: 1.2em;
  height: 1.2em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
