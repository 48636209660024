<template>
  <div class="_tooltip">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
};
</script>

<style lang="scss" scoped>
._tooltip {
  position: relative;
  background-color: var(--background-tooltip);
  border-radius: $border-radius;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  z-index: $zindex-fixed;
}
</style>
