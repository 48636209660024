<template>
  <div class="d-flex flex-column" style="min-height: 100%">
    <div class="p-4 w-100 text-center">
      <div v-if="$store.state.loginLogoSrc" class="text-center pb-4">
        <img class="logo logo-header" :src="$store.state.loginLogoSrc" alt="Login Logo" />
      </div>
      <div class="mb-3">
        <p class="mb-1">Please verify your email</p>
        <p class="diana-theme-text-secondary mb-0">You’re almost there! We sent email to:</p>
        <p class="diana-theme-text-secondary mb-0">
          {{ this.$store.state.verifyEmail.email }}
        </p>
      </div>
      <div class="mb-3">Click "Verify" to complete your onboarding</div>
      <a tabindex="1" class="mb-4 accessibility-link"
        @click.prevent="resendEmailVerification"
         :style='{color: $store.state.buttonColor, cursor: "pointer", textDecoration: "none"}'>
        Resend verification email
      </a>
      <!-- error text -->
      <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="mb-3" />

      <div v-if="$store.state.verifyEmail.retryUrl" class="w-100">
        <div class="d-inline-flex">
          <a @click.prevent="openHelpScreen" class="diana-theme-text-primary" style="cursor: pointer"
          >Need help?</a
          >
          <span class="mx-2 diana-theme-text-primary">&bull;</span>
          <a
            href="#"
            class="diana-theme-text-primary"
            @click.prevent="$store.commit('ovNavigation/navigate', 'login')"
          >Back to login</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import querystring from 'querystring';
import ErrorMessage from '@/components/ErorrMessage.vue';

export default {
  name: 'VerifyEmail',
  components: { ErrorMessage },
  data: () => ({
    isLoggingOut: false,
    status: {
      isLoading: false,
      errorMessage: '',
    },
  }),
  methods: {
    async logout() {
      if (this.isLoggingOut) return;
      try {
        this.isLoggingOut = true;
        await this.$request({
          url: '/Account/SignOut',
          method: 'GET',
        });
      } catch (e) {
        console.error('Logout Error', e);
      } finally {
        this.isLoggingOut = false;
        this.$store.dispatch('userProfile/loadProfile', { vm: this });
      }
    },
    async resendEmailVerification() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';

        await this.$request({
          url: this.$store.state.verifyEmail.retryUrl,
          method: 'GET',
        });
      } catch (e) {
        this.status.errorMessage = e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
    openHelpScreen() {
      // Small delay so users know what is happening
      setTimeout(() => {
        window.open(this.$store.state.helpUrl, '_blank', 'noopener noreferrer');
        this.clearMessages();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
