import { render, staticRenderFns } from "./SecurityForm.vue?vue&type=template&id=84f35fda&scoped=true"
import script from "./SecurityForm.vue?vue&type=script&lang=js"
export * from "./SecurityForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84f35fda",
  null
  
)

export default component.exports