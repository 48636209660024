<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="status.isLoading">
      <div class="d-flex align-items-center">
        <!-- title -->
        <div class="flex-fill">
          <h1 class="m-0">Company</h1>
        </div>

        <!-- save button -->
        <div>
          <button type="submit" class="btn btn-primary">
            <Spinner v-if="status.isLoading" />
            <span v-else>Save Changes</span>
          </button>
        </div>
      </div>

      <div class="mt-5">
        <!-- error message -->
        <AlertMessage v-model="status.errorMessage" class="mb-5 bg-danger" />

        <!-- success message -->
        <AlertMessage v-model="status.successMessage" class="mb-5 bg-success" />

        <div class="row">
          <!-- name -->
          <div class="col-6">
            <label class="small">Company name</label>
            <input v-model="formData.displayName" type="text" />
          </div>

          <!-- address -->
          <div class="col-6">
            <label class="small">Company address</label>
            <input v-model="formData.address" type="text" />
          </div>

          <!-- number -->
          <div class="col-6">
            <label class="small">Company number</label>
            <input v-model="formData.companyNumber" type="text" />
          </div>

          <!-- short code -->
          <div class="col-6">
            <label class="small">Company short code</label>
            <input v-model="formData.shortCode" type="text" />
          </div>

          <!-- bank details -->
          <div class="col-6">
            <label class="small">Company bank details</label>
            <textarea v-model="formData.bankAccount" rows="4"></textarea>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CompanyForm',
  data: () => ({
    formData: {
      displayName: '',
      address: '',
      companyNumber: '',
      shortCode: '',
      bankAccount: '',
    },
    status: {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    },
  }),
  computed: {
    ...mapState('userProfile', {
      userData: 'data',
    }),
  },
  created() {
    if (this.userData)
      this.formData = {
        ...this.formData,
        ...this.userData.company,
      };
  },
  methods: {
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        this.status.successMessage = '';

        await this.$request({
          url: `/api/v1.0/Company/${this.formData.id}/update`,
          method: 'POST',
          data: JSON.stringify(this.formData),
          contentType: 'application/json',
        });

        Object.assign(this.$store.state.userProfile.data.company, this.formData);
        this.status.successMessage = 'Changes saved successfully.';
      } catch (e) {
        this.status.errorMessage = e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
