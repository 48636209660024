var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TextDivider", [
    _c("span", { staticClass: "m-3 diana-theme-text-primary" }, [_vm._v("or")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }