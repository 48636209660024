var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.value,
          expression: "value",
        },
      ],
      ref: "AppTray",
      staticClass: "_app-tray py-3",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.$store.state.appDrawer.apps, function (app) {
        return _c(
          "a",
          {
            key: app.name,
            staticClass: "_item px-3 py-2",
            attrs: { href: app.href },
          },
          [
            _c("div", { staticClass: "_item-image-wrapper" }, [
              _c("img", { attrs: { src: app.image, alt: app.image } }),
            ]),
            _vm._v("\n    " + _vm._s(app.name) + "\n  "),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "px-3 mb-2" }, [
      _c("h6", { staticClass: "m-0 text-muted text-uppercase" }, [
        _vm._v("Switch to"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }