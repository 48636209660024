var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column",
      staticStyle: { "min-height": "100%" },
    },
    [
      _c(
        "div",
        { staticClass: "p-4 w-100 text-center" },
        [
          _vm.$store.state.loginLogoSrc
            ? _c("div", { staticClass: "text-center pb-4" }, [
                _c("img", {
                  staticClass: "logo logo-header",
                  attrs: {
                    src: _vm.$store.state.loginLogoSrc,
                    alt: "Login Logo",
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", { staticClass: "mb-1" }, [
              _vm._v("Please verify your email"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "diana-theme-text-secondary mb-0" }, [
              _vm._v("You’re almost there! We sent email to:"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "diana-theme-text-secondary mb-0" }, [
              _vm._v(
                "\n        " +
                  _vm._s(this.$store.state.verifyEmail.email) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }, [
            _vm._v('Click "Verify" to complete your onboarding'),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "mb-4 accessibility-link",
              style: {
                color: _vm.$store.state.buttonColor,
                cursor: "pointer",
                textDecoration: "none",
              },
              attrs: { tabindex: "1" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.resendEmailVerification.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      Resend verification email\n    ")]
          ),
          _vm._v(" "),
          _vm.status.errorMessage
            ? _c("ErrorMessage", {
                staticClass: "mb-3",
                model: {
                  value: _vm.status.errorMessage,
                  callback: function ($$v) {
                    _vm.$set(_vm.status, "errorMessage", $$v)
                  },
                  expression: "status.errorMessage",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.verifyEmail.retryUrl
            ? _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "d-inline-flex" }, [
                  _c(
                    "a",
                    {
                      staticClass: "diana-theme-text-primary",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openHelpScreen.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Need help?")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "mx-2 diana-theme-text-primary" }, [
                    _vm._v("•"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "diana-theme-text-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$store.commit(
                            "ovNavigation/navigate",
                            "login"
                          )
                        },
                      },
                    },
                    [_vm._v("Back to login")]
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }