<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="status.isLoading">
      <div class="d-flex align-items-center">
        <!-- title -->
        <div class="flex-fill">
          <h1 class="m-0">Settings/Preferences</h1>
        </div>

        <!-- save button -->
        <div>
          <button type="submit" class="btn btn-primary">
            <Spinner v-if="status.isLoading" />
            <span v-else>Save Changes</span>
          </button>
        </div>
      </div>

      <div class="mt-5">
        <!-- error message -->
        <AlertMessage v-model="status.errorMessage" class="mb-5 bg-danger" />

        <!-- success message -->
        <AlertMessage v-model="status.successMessage" class="mb-5 bg-success" />

        <div class="row">
          <!-- preferred language -->
          <div class="col-6">
            <label class="small">Preferred language</label>
            <input type="text" />
          </div>

          <!-- date pattern -->
          <div class="col-6">
            <label class="small">Date pattern</label>
            <input type="text" />
          </div>

          <!-- time pattern -->
          <div class="col-6">
            <label class="small">Time pattern</label>
            <input type="text" />
          </div>

          <!-- time zone -->
          <div class="col-6">
            <label class="small">Time zone</label>
            <input type="text" />
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
export default {
  name: 'SettingsForm',
  data: () => ({
    formData: {},
    status: {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    },
  }),
  methods: {
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        this.status.successMessage = '';

        throw new Error('Save endpoint is not ready yet.');
      } catch (e) {
        this.status.errorMessage = e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
s
