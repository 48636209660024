<template>
  <div v-if="value" class="_alert-message d-flex">
    <div class="flex-fill">
      <div v-for="(message, index) in messages" :key="index">{{ message }}</div>
    </div>
    <div v-if="$listeners.input">
      <span class="_close" @click.prevent="$emit('input', '')"> &#10799; </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: ['value'],
  computed: {
    messages() {
      return (this.value || '').split('\n');
    },
  },
};
</script>

<style lang="scss" scoped>
._alert-message {
  background-color: $primary;
  border-radius: $border-radius;
  padding: map_get($spacers, 3);
}

._close {
  display: block;
  user-select: none;
  cursor: pointer;
  transform: scale(1.5);
}
</style>
