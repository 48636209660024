const appDrawer = {
  state: {
    show: false,
    apps: [
      {
        name: 'Quaychain',
        image: 'https://cdn.rise-x.com/diana/images/QuayChain_Icon_64.png',
        href: 'https://quaychain-test.rise-x.com/',
      },
      {
        name: 'Biopass',
        image: 'https://cdn.rise-x.com/diana-login/assets/apps/biopass.png',
        href: 'https://biopass-dev.rise-x.com/',
      },
      {
        name: 'Systemedic',
        image: 'https://cdn.rise-x.com/diana-login/assets/apps/systemedic.png',
        href: 'https://systemedic-dev.rise-x.com/',
      },
      {
        name: 'Woodside',
        image: 'https://cdn.rise-x.com/diana-login/assets/apps/woodside.svg',
        href: 'https://woodside-dev.rise-x.com/',
      },
    ],
  },
  namespaced: true,
};

export default appDrawer;
