<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="status.isLoading">
      <div class="d-flex align-items-center">
        <!-- title -->
        <div class="flex-fill">
          <h1 class="m-0">Security</h1>
        </div>

        <!-- save button -->
        <div>
          <button type="submit" class="btn btn-primary">
            <Spinner v-if="status.isLoading" />
            <span v-else>Save Changes</span>
          </button>
        </div>
      </div>

      <div class="mt-5">
        <!-- error message -->
        <AlertMessage v-model="status.errorMessage" class="mb-5 bg-danger" />

        <!-- success message -->
        <AlertMessage v-model="status.successMessage" class="mb-5 bg-success" />

        <div class="row">
          <!-- current password -->
          <div class="col-6">
            <label class="small">Current password</label>
            <input
              v-model="formData.currentPassword"
              type="password"
              autocomplete="new-password"
              required="required"
            />
          </div>

          <!-- new password -->
          <div class="col-6">
            <label class="small">New password</label>
            <input
              v-model="formData.newPassword"
              type="password"
              autocomplete="new-password"
              required="required"
            />
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import querystring from 'querystring';

export default {
  name: 'SecurityForm',
  data: () => ({
    formData: {
      currentPassword: '',
      newPassword: '',
    },
    status: {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    },
  }),
  methods: {
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        this.status.successMessage = '';

        await this.$request({
          url: `/Account/ChangePassword?${querystring.encode(this.formData)}`,
          method: 'POST',
          data: JSON.stringify(this.formData),
          contentType: 'application/json',
        });

        this.formData.currentPassword = '';
        this.formData.newPassword = '';
        this.status.successMessage = 'Changes saved successfully.';
      } catch (e) {
        this.status.errorMessage =
          e.xHR && e.xHR.responseJSON && !e.xHR.responseJSON.login
            ? 'Invalid password.'
            : e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
