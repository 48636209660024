const verifyEmail = {
  state: {
    retryUrl: '',
    email: '',
    name: '',
  },
  namespaced: true,
};

export default verifyEmail;
