<template>
  <button class="tg-btn" type="button">
    <UserAvatar />
  </button>
</template>

<script>
export default {
  name: 'ToggleButton',
};
</script>

<style lang="scss" scoped>
.tg-btn {
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;

  &:hover {
    background-color: transparent !important;
    color: #07b999;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    left: 0;
    top: 0;
    transition: all 400ms ease-in-out;
    color: lime;
  }

  &._active:after {
    color: #07b999;
  }
}
</style>
