<template>
  <div class="position-relative p-4">
    <div>
      <!-- login logo -->
      <div v-if="$store.state.loginLogoSrc" class="text-center pb-4">
        <img class="logo logo-header" :src="$store.state.loginLogoSrc" alt="Login Logo" />
      </div>

      <!-- login options -->
      <div>
        <div
          class="align-left mb-3 diana-theme-text-secondary"
          style="display: flex; align-items: center"
        >
          <img :src="$resource['key-icon']" alt="google" class="logo logo-inner mr-2" />
          <span>Single sign on (SSO):</span>
        </div>
        <button
          ref="firstElement"
          tabindex="1"
          type="button"
          class="btn-lg btn-secondary w-100 mb-3 accessibility-element"
          @click.prevent="loginWithMicrosoft"
        >
          <img
            :src="$resource['microsoft-360-logo']"
            alt="Microsoft Logo"
            class="logo logo-button"
          />
          <span class="align-middle ml-2">Continue with Microsoft</span>
        </button>
        <button
          tabindex="2"
          type="button"
          class="btn-lg btn-secondary w-100 accessibility-element"
          @click.prevent="loginWithGoogle"
        >
          <img :src="$resource['google-logo']" alt="Google Logo" class="logo logo-button" />
          <span class="align-middle ml-2">Continue with Google</span>
        </button>
      </div>

      <OrDivider />

      <!-- login form -->
      <form @submit.prevent="submit" class="mt-1">
        <fieldset :disabled="status.isLoading">
          <p
            v-if="status.successMessage"
            v-text="status.successMessage"
            :style="{ color: $store.state.buttonColor }"
            class="mb-3"
          />

          <!-- email input -->
          <div class="position-relative">
            <input
              tabindex="3"
              v-model="formData.Email"
              type="email"
              autocomplete="email"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="off"
              class="input-lg text-left mb-3 accessibility-element"
              required="required"
              placeholder="Email address"
              :disabled="status.isSsoChecked"
            />
            <button
              v-show="status.isSsoChecked"
              class="btn end-icon"
              type="button"
              @click.prevent="clearEmail()"
            >
              <svg
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9734 2.20726L9.14007 0.373972C8.9008 0.149225 8.58727 0.0202686 8.25911 0.0116329C7.93095 0.00299726 7.61107 0.115285 7.36031 0.327137L1.33858 6.34887C1.12231 6.56697 0.98765 6.85282 0.957204 7.15846L0.669499 9.94853C0.660486 10.0465 0.673202 10.1453 0.706741 10.2378C0.74028 10.3303 0.793816 10.4143 0.863533 10.4838C0.926052 10.5458 1.0002 10.5949 1.08172 10.6282C1.16324 10.6615 1.25052 10.6783 1.33858 10.6778H1.3988L4.18887 10.4236C4.4945 10.3931 4.78036 10.2585 4.99846 10.0422L11.0202 4.02047C11.2539 3.77355 11.3802 3.44406 11.3714 3.10418C11.3627 2.76431 11.2195 2.44177 10.9734 2.20726ZM4.06843 9.08541L2.06119 9.27275L2.24184 7.26551L6.02215 3.53204L7.82867 5.33856L4.06843 9.08541ZM8.69847 4.44199L6.90534 2.64885L8.21005 1.31069L10.0366 3.13728L8.69847 4.44199Z"
                  fill="var(--text-primary)"
                  fill-opacity="0.54"
                />
              </svg>
            </button>
          </div>

          <!-- password input -->
          <div class="position-relative" v-if="status.isSsoChecked">
            <input
              tabindex="4"
              v-model="formData.Password"
              :type="this.passwordType"
              autofocus="autofocus"
              autocomplete="current-password"
              autocapitalize="none"
              autocorrect="off"
              class="input-lg mb-3 text-left accessibility-element"
              required="required"
              placeholder="Password"
            />
            <button type="button" class="btn end-icon" @click="toggle">
              <svg
                width="18"
                height="14"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z"
                  :fill="fillColor"
                  fill-opacity="0.54"
                />
              </svg>
            </button>
          </div>

          <div v-if="status.isSsoChecked" class="d-flex justify-content-end w-100 mb-3">
            <!-- forgot password text -->
            <a
              tabindex="5"
              href="#"
              class="diana-theme-text-primary accessibility-link"
              @click.prevent="$store.commit('ovNavigation/navigate', 'forgotPassword')"
              >Forgot password?</a
            >
          </div>

          <!-- error text -->
          <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="mb-3" />

          <!-- login button -->
          <button
            :tabindex="formData.Password.length === 0 ? -1 : 6"
            v-if="status.isSsoChecked"
            type="submit"
            class="btn-lg btn-primary w-100 mb-3 accessibility-element"
            :style="{ backgroundColor: $store.state.buttonColor }"
            :disabled="formData.Password.length === 0"
          >
            <Spinner v-if="status.isLoading" />
            <span v-else>Login</span>
          </button>

          <!-- continue with email button -->
          <button
            v-else
            :tabindex="formData.Email.length === 0 ? -1 : 6"
            type="submit"
            class="btn-lg btn-primary w-100 mb-3 accessibility-element"
            :style="{ backgroundColor: $store.state.buttonColor }"
            :disabled="formData.Email.length === 0"
            @click.prevent="checkSso"
          >
            <Spinner v-if="status.isLoading" />
            <span v-else>Continue</span>
          </button>
        </fieldset>
      </form>

      <!-- footer buttons -->
      <div class="text-center">
        <div class="d-inline-flex">
          <a
            tabindex="7"
            @click.prevent="openHelpScreen"
            class="diana-theme-text-primary accessibility-link"
            style="cursor: pointer"
            >Need help?</a
          >
          <span class="mx-2 diana-theme-text-primary" style="align-self: center">&bull;</span>
          <a
            tabindex="8"
            href="#"
            class="diana-theme-text-primary accessibility-link"
            @click.prevent="navigateToRegister"
            @blur="$emit('last-element-blur', $refs.firstElement)"
            >Create an account</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import querystring from 'querystring';
import { object, string } from 'yup';
import OrDivider from '../../components/OrDivider.vue';
import ErrorMessage from '@/components/ErorrMessage.vue';

const validateSchema = object({
  Email: string().required('Email address is required.').email('Invalid email address.'),
});

export default {
  data: () => ({
    formData: {
      Email: '',
      Password: '',
      RedirectUrl: window.location.href,
    },
    status: {
      errorMessage: '',
      successMessage: '',
      isSsoChecked: false,
      isLoading: false,
    },
    passwordType: 'password',
    fillColor: 'var(--text-primary)',
  }),
  methods: {
    async validateEmail() {
      try {
        const result = await validateSchema.validate(
          { ...this.formData },
          {
            abortEarly: false,
          },
        );
        this.status.errorMessage = '';
        return result;
      } catch (err) {
        this.status.errorMessage = err.message;
        return false;
      }
    },
    toggle() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      this.fillColor =
        this.passwordType === 'text' ? this.$store.state.buttonColor : 'var(--text-primary)';
    },
    clearMessages() {
      this.status.errorMessage = '';
      this.status.successMessage = '';
    },
    clearEmail() {
      this.formData.Email = '';
      this.status.isSsoChecked = false;
      this.clearMessages();
    },
    async submit() {
      if (!(await this.validateEmail())) {
        this.status.isLoading = false;
        return;
      }
      try {
        this.clearMessages();
        this.status.isLoading = true;
        this.$store.state.loginRedirectUrl = '';

        const formData = new FormData();
        formData.append('Email', this.formData.Email);
        formData.append('Password', this.formData.Password);
        formData.append('RedirectUrl', this.formData.RedirectUrl);

        const data = await this.$request({
          url: '/account/externalLogin',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        if (data) {
          this.$store.state.loginRedirectUrl = data;
        }

        if (this.$store.state.loginRedirectUrl) {
          window.location.href = this.$store.state.loginRedirectUrl;
        }

        await this.$store.dispatch('userProfile/loadProfile', { vm: this });
      } catch (e) {
        this.status.errorMessage =
          e.xHR && e.xHR.responseJSON && !e.xHR.responseJSON.login
            ? 'Invalid email or password.'
            : e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
    async checkSso() {
      if (!(await this.validateEmail())) {
        return;
      }
      try {
        this.clearMessages();
        this.status.isLoading = true;
        this.$store.state.loginRedirectUrl = '';

        const formData = new FormData();
        formData.append('Email', this.formData.Email);

        const data = await this.$request({
          url: '/account/checkSso',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        // If the user is registered as an 'aad' or 'google' user they should be redirected.
        // Note that due to the architecture design, a user may have one or more logins.
        // For example, a user might register with Microsoft, Google, and password login.
        // In this case, the database will return the first valid login method for the user.
        if (data && data.length > 0) {
          if (data === 'aad') {
            return this.loginWithMicrosoft();
          }
          if (data === 'google') {
            return this.loginWithGoogle();
          }
        }

        // Store the email in store state for prefilling 'forgot password' screen.
        this.$store.state.username = this.formData.Email;
        this.status.isSsoChecked = true;
      } catch (e) {
        this.status.errorMessage =
          e.xHR && e.xHR.responseJSON && !e.xHR.responseJSON.login
            ? 'Invalid email or password.'
            : e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
    loginWith(name, provider) {
      this.status.isLoading = true;

      const query = {
        provider,
        returnUrl:
          this.appendAnonIdToUrl(this.$store.state.loginRedirectUrl) || window.location.href,
      };

      window.location.href = `${
        this.$store.state.apiBaseUrl
      }/External/Challenge?${querystring.encode(query)}`;
    },
    loginWithMicrosoft() {
      return this.loginWith('Microsoft', 'aad');
    },
    loginWithGoogle() {
      return this.loginWith('Google', 'Google');
    },
    appendAnonIdToUrl(url) {
      const currentUrl = new URL(window.location.href);
      const anonId = currentUrl.searchParams.get('anonId');

      if (!anonId) {
        return url;
      }

      const updatedUrl = new URL(url);
      updatedUrl.searchParams.set('anonId', anonId);

      return updatedUrl.href;
    },
    openHelpScreen() {
      // Small delay so users know what is happening
      setTimeout(() => {
        window.open(this.$store.state.helpUrl, '_blank', 'noopener noreferrer');
        this.clearMessages();
      }, 500);
    },
    navigateToRegister() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);

      query['plugin-rx-action'] = 'register';

      const newQueryString = querystring.encode(query);

      const newURL = `${window.location.pathname}?${newQueryString}`;

      window.history.replaceState({}, '', newURL);
      this.$store.commit('ovNavigation/navigate', 'register');
    },
  },
  components: { ErrorMessage, OrDivider },
};
</script>
