import UserProfile from '@/screens/UserProfile';
import UserProfileExtension from '@/screens/UserProfileExtension';
import ResetPassword from '@/screens/ResetPassword';

const navigation = {
  state: {
    // if set the extension content will open
    extensionPayload: null,

    // current screen
    currentScreen: 'login',

    // screens map
    screens: {
      userProfile: {
        component: UserProfile,
        extension: UserProfileExtension,
      },
      resetPassword: {
        component: ResetPassword,
        extension: null,
      },
    },
  },
  mutations: {
    // navigate screen
    navigate(state, screen) {
      state.extensionPayload = null;
      state.currentScreen = screen;
    },
  },
  namespaced: true,
};

export default navigation;
