var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c("div", { staticClass: "_error-message d-flex" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.8585 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.8585 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM8 4.25C7.80109 4.25 7.61032 4.32902 7.46967 4.46967C7.32902 4.61032 7.25 4.80109 7.25 5V8.75C7.25 8.94891 7.32902 9.13968 7.46967 9.28033C7.61032 9.42098 7.80109 9.5 8 9.5C8.19891 9.5 8.38968 9.42098 8.53033 9.28033C8.67098 9.13968 8.75 8.94891 8.75 8.75V5C8.75 4.80109 8.67098 4.61032 8.53033 4.46967C8.38968 4.32902 8.19891 4.25 8 4.25ZM8 11.75C8.19891 11.75 8.38968 11.671 8.53033 11.5303C8.67098 11.3897 8.75 11.1989 8.75 11C8.75 10.8011 8.67098 10.6103 8.53033 10.4697C8.38968 10.329 8.19891 10.25 8 10.25C7.80109 10.25 7.61032 10.329 7.46967 10.4697C7.32902 10.6103 7.25 10.8011 7.25 11C7.25 11.1989 7.32902 11.3897 7.46967 11.5303C7.61032 11.671 7.80109 11.75 8 11.75Z",
                  fill: "#E55050",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column ml-2" },
            _vm._l(_vm.messages, function (message, index) {
              return _c("div", { key: index }, [_vm._v(_vm._s(message))])
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }