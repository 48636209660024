<template>
  <div class="d-flex flex-column mw-100" style="min-height: 100%">
    <!-- header -->
    <div class="w-100 flex-fill d-flex align-items-center">
      <div class="w-100 px-4 pt-5">
        <div class="text-center">
          <img :src="$resource['rise-x-logo']" style="max-width: 180px" alt="Rise-X" />
          <h5 class="mt-3 font-weight-bold">Reset Password</h5>
        </div>
      </div>
    </div>

    <!-- body -->
    <div class="w-100 p-5 flex-fill d-flex">
      <div class="w-100">
        <form @submit.prevent="submit">
          <fieldset :disabled="status.isLoading">
            <input
              v-model="formData.userEmail"
              type="email"
              autocomplete="username"
              disabled="disabled"
              class="input-lg text-left"
              required="required"
              placeholder="Email"
            />

            <input
              v-model="formData.newPassword"
              type="password"
              autocomplete="new-password"
              class="input-lg text-left"
              required="required"
              placeholder="Enter new password"
            />

            <div v-if="formData.newPassword">
              <input
                v-model="formData.reTypePassword"
                type="password"
                autocomplete="new-password"
                class="input-lg text-left"
                required="required"
                placeholder="Retype new password"
              />
              <PasswordStrength class="mb-3" :value="formData.newPassword" />
            </div>

            <!-- error message -->
            <AlertMessage v-model="status.errorMessage" class="mb-4 bg-danger" />

            <!-- success message -->
            <AlertMessage v-model="status.successMessage" class="mb-4 bg-primary" />

            <button type="submit" class="btn-lg btn-primary w-100 text-uppercase">
              <Spinner v-if="status.isLoading" />
              <span v-else>Change Password</span>
            </button>
          </fieldset>
        </form>
      </div>
    </div>

    <!-- footer -->
    <div class="w-100 border-top">
      <div class="p-4 text-center">
        <h6 class="m-0">
          <a href="#" class="font-weight-bold" @click.prevent="goHome"> Go Home </a>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import querystring from 'querystring';
import PasswordStrength from '@/components/PasswordStrength';

export default {
  components: {
    PasswordStrength,
  },
  data: () => ({
    formData: {
      userEmail: '',
      code: '',
      newPassword: '',
      reTypePassword: '',
    },
    status: {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    },
  }),
  computed: {
    urlQuery() {
      const search = (window.location.search || '').replace(/^\?/, '');
      return querystring.decode(search);
    },
  },
  created() {
    //?plugin-rx-action=resetPassword&email=example@email.com&code=abcdefghijklmnop
    if (this.urlQuery['email'] && this.urlQuery['code']) {
      this.formData.userEmail = this.urlQuery['email'];
      this.formData.code = this.urlQuery['code'];
    } else this.goHome();
  },
  methods: {
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        const formData = new FormData();
        formData.append('userEmail', this.formData.userEmail);
        formData.append('code', this.formData.code);
        formData.append('newPassword', this.formData.newPassword);

        if (this.formData.newPassword !== this.formData.reTypePassword) {
          throw new Error('Retype password did not match.');
        }

        const data = await this.$request({
          url: '/account/setNewPassword',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        if (data.succeeded)
          this.status.successMessage = 'You have successfully updated your password.';
        else throw new Error('Reset password was not successful.');
      } catch (e) {
        this.status.errorMessage = e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
    goHome() {
      const query = JSON.parse(JSON.stringify(this.urlQuery));
      delete query['plugin-rx-action'];
      delete query['email'];
      delete query['code'];
      window.history.replaceState(undefined, undefined, `?${querystring.encode(query)}`);
      this.$store.dispatch('userProfile/loadProfile', { vm: this });
    },
  },
};
</script>

<style lang="scss" scoped></style>
