var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_overlay d-flex flex-column" },
    [
      _c("Header"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex-fill d-flex align-items-center justify-content-center p-3",
        },
        [
          _c(
            "div",
            { staticClass: "_content", style: _vm.cssOverlayVars },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isRegisterPage()
        ? _c("footer", [
            _c("p", { staticClass: "text-center pt-3 _privacy-text" }, [
              _vm._v("\n      By clicking Register, you accept our\n      "),
              _c(
                "a",
                {
                  staticClass: "diana-theme-text-primary",
                  attrs: { href: _vm.$resource["terms-url"], target: "_blank" },
                },
                [_vm._v("Terms of Service")]
              ),
              _vm._v(",\n      "),
              _c(
                "a",
                {
                  staticClass: "diana-theme-text-primary",
                  attrs: {
                    href: _vm.$resource["privacy-url"],
                    target: "_blank",
                  },
                },
                [_vm._v("Privacy Policy.")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }