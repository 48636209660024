const userProfile = {
  state: {
    // user session data
    data: null,

    // status of user session data
    status: {
      isLoading: false,
      errorMessage: '',
    },
  },
  actions: {
    // load user session data
    async loadProfile({ state }, { vm, callback = () => {} }) {
      try {
        state.status.isLoading = true;
        state.status.errorMessage = '';

        const isAuthenticated = await vm.$request({
          url: '/Account/isAuthenticated',
          method: 'GET',
        });

        state.data = isAuthenticated
          ? await vm.$request({
              url: '/Account/GetSessionInfo',
              method: 'GET',
            })
          : null;

        if (state.data) {
          vm.$store.commit('ovNavigation/navigate', null);
          vm.$store.commit('navigation/navigate', 'userProfile');
        } else {
          vm.$store.commit('navigation/navigate', 'login');
        }
      } catch (e) {
        state.status.errorMessage = e.message;
      } finally {
        state.status.isLoading = false;
        callback(state.data);
      }
    },
  },
  namespaced: true,
};

export default userProfile;
