var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column mw-100",
      staticStyle: { "min-height": "100%" },
    },
    [
      _c("div", { staticClass: "w-100 flex-fill d-flex align-items-center" }, [
        _c("div", { staticClass: "w-100 px-4 pt-5" }, [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: { "max-width": "180px" },
              attrs: { src: _vm.$resource["rise-x-logo"], alt: "Rise-X" },
            }),
            _vm._v(" "),
            _c("h5", { staticClass: "mt-3 font-weight-bold" }, [
              _vm._v("Reset Password"),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 p-5 flex-fill d-flex" }, [
        _c("div", { staticClass: "w-100" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "fieldset",
                { attrs: { disabled: _vm.status.isLoading } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.userEmail,
                        expression: "formData.userEmail",
                      },
                    ],
                    staticClass: "input-lg text-left",
                    attrs: {
                      type: "email",
                      autocomplete: "username",
                      disabled: "disabled",
                      required: "required",
                      placeholder: "Email",
                    },
                    domProps: { value: _vm.formData.userEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "userEmail", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.newPassword,
                        expression: "formData.newPassword",
                      },
                    ],
                    staticClass: "input-lg text-left",
                    attrs: {
                      type: "password",
                      autocomplete: "new-password",
                      required: "required",
                      placeholder: "Enter new password",
                    },
                    domProps: { value: _vm.formData.newPassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.formData,
                          "newPassword",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.formData.newPassword
                    ? _c(
                        "div",
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.reTypePassword,
                                expression: "formData.reTypePassword",
                              },
                            ],
                            staticClass: "input-lg text-left",
                            attrs: {
                              type: "password",
                              autocomplete: "new-password",
                              required: "required",
                              placeholder: "Retype new password",
                            },
                            domProps: { value: _vm.formData.reTypePassword },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "reTypePassword",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("PasswordStrength", {
                            staticClass: "mb-3",
                            attrs: { value: _vm.formData.newPassword },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("AlertMessage", {
                    staticClass: "mb-4 bg-danger",
                    model: {
                      value: _vm.status.errorMessage,
                      callback: function ($$v) {
                        _vm.$set(_vm.status, "errorMessage", $$v)
                      },
                      expression: "status.errorMessage",
                    },
                  }),
                  _vm._v(" "),
                  _c("AlertMessage", {
                    staticClass: "mb-4 bg-primary",
                    model: {
                      value: _vm.status.successMessage,
                      callback: function ($$v) {
                        _vm.$set(_vm.status, "successMessage", $$v)
                      },
                      expression: "status.successMessage",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-lg btn-primary w-100 text-uppercase",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.status.isLoading
                        ? _c("Spinner")
                        : _c("span", [_vm._v("Change Password")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 border-top" }, [
        _c("div", { staticClass: "p-4 text-center" }, [
          _c("h6", { staticClass: "m-0" }, [
            _c(
              "a",
              {
                staticClass: "font-weight-bold",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goHome.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Go Home ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }